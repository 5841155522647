<app-dark-back-button
  *ngIf="viewModel.showBackButton$ | async"
  (backClicked)="viewModel.goBack()"
  [paddingBottomRem]="2"></app-dark-back-button>
<ng-container [ngSwitch]="viewModel.authFlow$ | async">
  <!--  Sign In Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.SignIn">
    <app-sign-in (loadingOpts)="loadingOpts.emit($event)"></app-sign-in>
  </ng-container>
  <!--  Forgot Password Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.ForgotPassword">
    <app-forgot-password (loadingOpts)="loadingOpts.emit($event)"></app-forgot-password>
  </ng-container>
  <!--  Reset Password Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.ResetPassword">
    <app-reset-password (loadingOpts)="loadingOpts.emit($event)"></app-reset-password>
  </ng-container>
  <!--  Member Welcome Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.MemberWelcome">
    <app-member-welcome></app-member-welcome>
  </ng-container>
  <!--  Member Setup Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.MemberSetup">
    <app-member-setup></app-member-setup>
  </ng-container>
  <!--  Member Enter Email Verification Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.MemberEnterEmailVerification">
    <app-member-enter-email-verification></app-member-enter-email-verification>
  </ng-container>
  <!--  Member Send Email Verification Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.MemberSendEmailVerification">
    <app-member-send-email-verification></app-member-send-email-verification>
  </ng-container>
  <!--  Enter MFA Code Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.MemberMFAVerification">
    <app-verify-mfa (loadingOpts)="loadingOpts.emit($event)"></app-verify-mfa>
  </ng-container>
</ng-container>
