import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { inject, Injectable } from '@angular/core';
import { ToastService } from '../../../../../services/toast-service';
import { Router } from '@angular/router';
import { ReleaseNotesDomainModel } from '../../../../../domainModels/release-notes-domain-model';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, shareReplay } from 'rxjs/operators';
import { ODataQueryOptions } from '../../../../../models/shared/odata-query-options';
import { CustomError } from '../../../../../models/shared/custom-error';
import { OdataFilterGenerator } from '../../../../../interfaces/odata-filter-generator';
import { DropDownItem } from '../../../../../models/shared/stylesheet/drop-down-item';
import { ReleaseNotesTableOrderbyRequest } from '../../../../../models/shared/odata-sorting-requests/release-notes-table-orderby-request';
import { ReleaseNotesTableFilterRequest } from '../../../../../models/shared/odata-filter-requests/release-notes-table-filter-request';
import { SlidingWindowOptions } from '../../../../../models/shared/sliding-window-options';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from '../../../../../utils/date-utils';
import { StringExtensions } from '../../../../../utils/string.extensions';
import { ReleaseNoteDocumentVersion } from '../../../../../models/release-notes/release-note-document-version';
import { ReleaseNoteStatusEnum } from '../../../../../models/enum/shared/release-note-status.enum';
import { PortalTypeIdEnum } from '../../../../../models/enum/shared/portal-type-id.enum';

@Injectable()
export class ReleaseNotesTableViewModel extends BaseViewModel {
  constructor(private toastService: ToastService, private router: Router) {
    super();
  }

  private domainModel = inject(ReleaseNotesDomainModel);

  public readonly releaseNoteDocuments$ = this.domainModel.releaseNoteDocuments$;
  public readonly releaseNoteDocumentsTotalCount$ = this.domainModel.releaseNoteDocumentsTotalCount$;

  private _releaseNotesTableOrderbyRequest = new BehaviorSubject<ReleaseNotesTableOrderbyRequest>(
    new ReleaseNotesTableOrderbyRequest()
  );
  public readonly releaseNotesTableOrderbyRequest$ = this
    ._releaseNotesTableOrderbyRequest as Observable<ReleaseNotesTableOrderbyRequest>;

  private _releaseNotesTableFilterRequest = new BehaviorSubject<ReleaseNotesTableFilterRequest>(
    new ReleaseNotesTableFilterRequest()
  );
  public readonly releaseNotesTableFilterRequest$ = this._releaseNotesTableFilterRequest
    .distinctUniquelyIdentifiable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private _showFilterWindow = new BehaviorSubject<boolean>(false);
  public readonly showFilterWindow$ = this._showFilterWindow as Observable<boolean>;

  private _showSearchBars = new BehaviorSubject<boolean>(false);
  public readonly showSearchBars$ = this._showSearchBars as Observable<boolean>;

  private _activeFilterCount = new BehaviorSubject<number>(0);
  public readonly activeFilterCount$ = this._activeFilterCount as Observable<number>;

  private _activeSearchCriteria = new BehaviorSubject<number>(0);
  public readonly activeSearchCriteria$ = this._activeSearchCriteria as Observable<number>;

  private _loadingReleaseNotes = new BehaviorSubject<boolean>(false);
  public loadingReleaseNotes$ = this._loadingReleaseNotes as Observable<boolean>;

  private _currPageNumber = new BehaviorSubject<number>(0);
  public readonly currPageNumber$ = this._currPageNumber as Observable<number>;

  private _pageSize = new BehaviorSubject<number>(10);
  public readonly pageSize$ = this._pageSize as Observable<number>;

  private _searchCriteriaChangedSubject = new Subject<ReleaseNotesTableFilterRequest>();
  public readonly searchCriteriaChanged$ = this
    ._searchCriteriaChangedSubject as Observable<ReleaseNotesTableFilterRequest>;

  private _selectedStartDate = new BehaviorSubject<string>('');
  public readonly selectedStartDate$ = this._selectedStartDate as Observable<string>;

  private _selectedEndDate = new BehaviorSubject<string>('');
  public readonly selectedEndDate$ = this._selectedEndDate as Observable<string>;

  private _selectedDates = new BehaviorSubject<NgbDate[]>([]);
  public readonly selectedDates$ = this._selectedDates as Observable<NgbDate[]>;

  private _selectedVersions = new BehaviorSubject<ReleaseNoteDocumentVersion[]>([]);
  public readonly selectedVersions$ = this._selectedVersions as Observable<ReleaseNoteDocumentVersion[]>;

  public clearDates$ = new Subject<void>();

  public statusDropdowns$ = of([
    new DropDownItem($localize`Released`, ReleaseNoteStatusEnum.Released, false),
    new DropDownItem($localize`Unreleased`, ReleaseNoteStatusEnum.Unreleased, false)
  ]);

  public portalTypeDropdowns$ = of([
    new DropDownItem($localize`Internal Portal`, PortalTypeIdEnum.Internal, false),
    new DropDownItem($localize`Employer Portal`, PortalTypeIdEnum.Member, false),
    new DropDownItem($localize`Member Portal`, PortalTypeIdEnum.Employer, false)
  ]);

  public filterWindowOptions$ = of(
    new SlidingWindowOptions($localize`Filters`, $localize`Clear All`, $localize`Update Filters`)
  );

  private listenToFetchReleaseNotes = combineLatest([
    this.releaseNotesTableFilterRequest$,
    this.releaseNotesTableOrderbyRequest$,
    this.currPageNumber$,
    this.pageSize$
  ])
    .pipe(debounceTime(100))
    .subscribeWhileAlive({
      owner: this,
      next: ([filterRequest, orderbyRequest, currPage, pageSize]) => {
        this.fetchReleaseNoteDocuments(filterRequest, orderbyRequest, currPage, pageSize);
      }
    });

  private searchCriteriaChangedSubscription = this.searchCriteriaChanged$
    .distinctUniquelyIdentifiable()
    .pipe(debounceTime(500))
    .subscribeWhileAlive({
      owner: this,
      next: req => {
        this._releaseNotesTableFilterRequest.next(req);
        this._activeSearchCriteria.next(req.getSearchCount());
      }
    });

  public handleSortReq(sortReq: string): void {
    this._releaseNotesTableOrderbyRequest.next(new ReleaseNotesTableOrderbyRequest(sortReq));
  }

  public searchCriteriaChanged(req: OdataFilterGenerator): void {
    const copy = Object.assign(new ReleaseNotesTableFilterRequest(), req);
    this._searchCriteriaChangedSubject.next(copy as ReleaseNotesTableFilterRequest);
  }

  public updateCurrPageNumber(currPage: number): void {
    this._currPageNumber.next(currPage);
  }

  public toggleSearchBars(): void {
    this.showSearchBars$.once(show => {
      this._showSearchBars.next(!show);
    });
  }

  public toggleFilterWindow(): void {
    this.showFilterWindow$.once(show => {
      this._showFilterWindow.next(!show);
    });
  }

  public resetFilterForm(): void {
    this.releaseNotesTableFilterRequest$.once(req => {
      const copy = Object.assign(new ReleaseNotesTableFilterRequest(), req);
      copy.clearFilters();
      this._releaseNotesTableFilterRequest.next(copy as ReleaseNotesTableFilterRequest);
      this._activeFilterCount.next(0);
      this._selectedDates.next([]);
      this._selectedStartDate.next('');
      this._selectedEndDate.next('');
      this.clearDates$.next();
    });
  }

  public setDateFilters(dates: NgbDate[]): void {
    if (dates.length === 1) {
      this._selectedStartDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
    } else if (dates.length === 2) {
      this._selectedStartDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
      this._selectedEndDate.next(DateUtils.convertNgbDateObjectToString(dates[1]));
    }
    this._selectedDates.next(dates);
  }

  public resetFilterField(fieldKeys: string[]): void {
    this.releaseNotesTableFilterRequest$.once(req => {
      const reqCopy = Object.assign(new ReleaseNotesTableFilterRequest(), req);
      fieldKeys.forEach(k => {
        if (k.includes('Date' || 'search')) {
          reqCopy[k] = '';
          this._selectedDates.next([]);
          this._selectedEndDate.next('');
          this._selectedStartDate.next('');
        } else {
          reqCopy[k] = [];
        }
      });
      this._releaseNotesTableFilterRequest.next(reqCopy as ReleaseNotesTableFilterRequest);
      this._activeFilterCount.next(reqCopy.getFilterCount());
    });
  }

  public clearAllFilters(): void {
    this._releaseNotesTableFilterRequest.next(new ReleaseNotesTableFilterRequest());
    this._releaseNotesTableOrderbyRequest.next(new ReleaseNotesTableOrderbyRequest());
    this._activeFilterCount.next(0);
    this._activeSearchCriteria.next(0);
    this.clearDates$.next();
  }

  public deleteReleaseNoteDocument(id: string): void {
    this.domainModel.deleteReleaseNoteDocument(id).subscribe({
      next: () => {
        this.toastService.publishSuccessMessage(
          $localize`Release Note Deleted`,
          $localize`The release note has been deleted.`
        );
        this.pageSize$.once(pageSize => this._pageSize.next(pageSize));
      },
      error: (error: CustomError) => {
        this.toastService.publishError(error);
      }
    });
  }

  public filterFormSubmitted(req: ReleaseNotesTableFilterRequest): void {
    const copy = Object.assign(new ReleaseNotesTableFilterRequest(), req);
    this.generateDatesFromFilterRequest(copy);
    this._activeFilterCount.next(copy.getFilterCount());
    this._releaseNotesTableFilterRequest.next(copy);
  }

  private generateDatesFromFilterRequest(req: ReleaseNotesTableFilterRequest): void {
    const dates = [];
    if (!!req.startDate) {
      dates.push(StringExtensions.convertDateStringToNgbDate(req.startDate));
    }
    if (!!req.endDate) {
      dates.push(StringExtensions.convertDateStringToNgbDate(req.endDate));
    }
    this.setDateFilters(dates);
  }

  private fetchReleaseNoteDocuments(
    filterReq: ReleaseNotesTableFilterRequest,
    orderbyReq: ReleaseNotesTableOrderbyRequest,
    currPage: number,
    pageSize: number,
    showSpinner = true
  ): void {
    const lm = $localize`Loading Release Notes`;
    if (showSpinner) {
      this._loadingOpts.addRequest(lm);
    }
    this._loadingReleaseNotes.next(true);
    const oDataParams = new ODataQueryOptions();
    const filterString = filterReq.getFilterString();
    const orderbyString = orderbyReq.getOrderByString();
    if (!!filterString) {
      oDataParams.setFilter(filterString);
    }
    if (!!orderbyString) {
      oDataParams.setOrderBy(orderbyString);
    }
    oDataParams.setCount(true);
    oDataParams.setTop(pageSize);
    oDataParams.setSkip(currPage * pageSize);
    this.domainModel.getReleaseNoteDocuments(oDataParams).subscribe({
      complete: () => {
        this._loadingOpts.removeRequest(lm);
        this._loadingReleaseNotes.next(false);
      },
      error: (error: CustomError) => {
        this._loadingOpts.removeRequest(lm);
        this._loadingReleaseNotes.next(false);
        this.toastService.publishError(error);
      }
    });
  }

  setupBindings(): void {}

  setupViews(): void {}
}
