export enum InternalUserAuthFlow {
  SignIn = 0,
  ForgotPassword = 1,
  ResetPassword = 2,
  SetNewPassword = 3,
  InternalUserChoosePassword = 4,
  InternalUserSetupMfa = 5,
  InternalVerifyCode = 6
}

export function InternalUserAuthFlowTitle(af: InternalUserAuthFlow): string {
  switch (af) {
    case InternalUserAuthFlow.InternalUserChoosePassword:
      return $localize`Create a New Password`;
    case InternalUserAuthFlow.InternalVerifyCode:
      return $localize`Enter Verification Code`;
    default:
      return '';
  }
}

export function InternalUserAuthFlowSubtext(af: InternalUserAuthFlow): string {
  switch (af) {
    case InternalUserAuthFlow.SignIn:
      return $localize`Sign In`;
    case InternalUserAuthFlow.ForgotPassword:
      return $localize`Forgot Password`;
    case InternalUserAuthFlow.ResetPassword:
      return $localize`Reset Password`;
    case InternalUserAuthFlow.SetNewPassword:
      return $localize`Set New Password`;
    case InternalUserAuthFlow.InternalUserChoosePassword:
      // eslint-disable-next-line max-len
      return $localize`Set a new password to access your account. You will have to sign back into your account using your new password once it has been set.'`;
    case InternalUserAuthFlow.InternalVerifyCode:
      return $localize`Enter the six-digit code from your authentication app into the field below.`;
    default:
      return '';
  }
}
