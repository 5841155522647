import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-black-back-button',
  templateUrl: './black-back-button.component.html',
  styleUrls: ['./black-back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlackBackButtonComponent {
  @Input() paddingBottomRem = 0;
  @Input() label = $localize`Back`;
  @Output() backClicked = new EventEmitter(true);
}
