export enum EmployerAuthFlow {
  SignIn = 0,
  ForgotPassword = 1,
  ResetPassword = 2,
  SetNewPassword = 3,
  EmployerChoosePassword = 4,
  EmployerMFAVerification = 5
}

export function EmployerAuthFlowTitle(af: EmployerAuthFlow): string {
  switch (af) {
    case EmployerAuthFlow.SignIn:
      return $localize`Sign In`;
    case EmployerAuthFlow.ResetPassword:
      return $localize`Reset Password`;
    case EmployerAuthFlow.SetNewPassword:
      return $localize`Set New Password`;
    case EmployerAuthFlow.EmployerChoosePassword:
      return $localize`Create a New Password`;
    default:
      return '';
  }
}

export function EmployerAuthFlowSubtext(af: EmployerAuthFlow): string {
  switch (af) {
    case EmployerAuthFlow.SignIn:
      return '';
    case EmployerAuthFlow.ResetPassword:
      return '';
    case EmployerAuthFlow.SetNewPassword:
      return $localize`You are required to enter a new password for your account.`;
    case EmployerAuthFlow.EmployerChoosePassword:
      return $localize`Set a new password to access your account.`;
    default:
      return '';
  }
}
