import { Deserializable } from '../../protocols/deserializable';
import { GoverningJurisdictionType } from '../../employers/employer-history/types/governing-jurisdiction-type';
import { EmployerPlanDesignatedUnit } from '../../employers/plans/employer-plan-designated-unit';
import { EmploymentType } from '../../employers/employer-history/types/employment-type';
import { EmploymentStatusType } from '../../employers/employer-history/types/employment-status-type';
import * as moment from 'moment';
import { State } from '../../types/dto/state';
import { LeaveDetails } from './leave-details';

export class EmploymentHistory implements Deserializable {
  id!: number;
  memberId!: number;
  employerId!: number;
  employer!: string;
  designatedUnitId!: number | null;
  designatedUnit!: EmployerPlanDesignatedUnit;
  governingJurisdictionId!: number;
  governingJurisdiction!: GoverningJurisdictionType;
  provinceOfEmploymentId!: number;
  provinceOfEmployment!: State;
  employmentTypeId!: number;
  employmentType!: EmploymentType;
  employmentStatusId!: number;
  onLeave!: boolean;
  employmentStatus!: EmploymentStatusType;
  leaveDetailsId!: number | null;
  leaveDetails!: LeaveDetails | null;
  workFromHome!: boolean;
  startDate!: Date;
  endDate!: Date | null;
  contributionStartDate!: Date;
  lastExpectedContributionDate!: Date | null;
  lastProcessedContributionDate!: Date | null;
  annualSalary!: number | null;

  // Not From API
  public formattedStartDate!: string;
  public formattedEndDate!: string | null;
  public formattedContributionStartDate!: string;
  public formattedLastExpectedContributionDate!: string;
  public formattedLastProcessedContributionDate!: string;

  constructor() {
    this.onDeserialize();
  }

  onDeserialize(): void {
    if (!!this.startDate) {
      this.startDate = new Date(this.startDate);
      this.formattedStartDate = moment(this.startDate).utc().format('MMM, DD, YYYY');
    }
    if (!!this.endDate) {
      this.endDate = new Date(this.endDate);
      this.formattedEndDate = moment(this.endDate).utc().format('MMM, DD, YYYY');
    }
    if (!!this.contributionStartDate) {
      this.contributionStartDate = new Date(this.contributionStartDate);
      this.formattedContributionStartDate = moment(this.contributionStartDate).utc().format('MMM, DD, YYYY');
    }
    if (!!this.lastExpectedContributionDate) {
      this.lastExpectedContributionDate = new Date(this.lastExpectedContributionDate);
      this.formattedLastExpectedContributionDate = moment(this.lastExpectedContributionDate)
        .utc()
        .format('MMM, DD, YYYY');
    }
    if (!!this.lastProcessedContributionDate) {
      this.lastProcessedContributionDate = new Date(this.lastProcessedContributionDate);
      this.formattedLastProcessedContributionDate = moment(this.lastProcessedContributionDate)
        .utc()
        .format('dd / mm / yyyy');
    }
    if (!!this.designatedUnit) {
      this.designatedUnit = window?.injector?.Deserialize?.instanceOf(EmployerPlanDesignatedUnit, this.designatedUnit);
    }
    if (!!this.governingJurisdiction) {
      this.governingJurisdiction = window?.injector?.Deserialize?.instanceOf(
        GoverningJurisdictionType,
        this.governingJurisdiction
      );
    }
    if (!!this.provinceOfEmployment) {
      this.provinceOfEmployment = window?.injector?.Deserialize?.instanceOf(State, this.provinceOfEmployment);
    }
    if (!!this.employmentType) {
      this.employmentType = window?.injector?.Deserialize?.instanceOf(EmploymentType, this.employmentType);
    }
    if (!!this.employmentStatus) {
      this.employmentStatus = window?.injector?.Deserialize?.instanceOf(EmploymentStatusType, this.employmentStatus);
    }
    if (!!this.leaveDetails) {
      this.leaveDetails = window?.injector?.Deserialize?.instanceOf(LeaveDetails, this.leaveDetails);
    }
    if (!this.leaveDetails) {
      this.leaveDetails = new LeaveDetails();
    }
  }
}
