// noinspection JSUnusedLocalSymbols

import { BaseViewModel } from './base-view-model';
import { inject, Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDomainModel } from '../../domainModels/user-domain-model';
import { UserAPI } from '../../api/user-api';
import { ToastService } from '../../services/toast-service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { BaseUser } from './base-user';
import { CacheService } from '../../services/cache-service';
import { SessionContainer } from '../shared/session-container';
import { DefaultCacheKey } from '../enum/shared/default-cache-key.enum';
import { CachePolicy } from '../enum/shared/cachable-image-policy.enum';
import { EulaRequiredValidatorDirective } from '../../views/shared/directives/eula-required-validator-directive';
import { ModalEula } from '../../modals/modal-eula';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PasswordInputUtils } from '../../utils/password-input-utils';
import { SessionService } from '../../services/session-service';

@Injectable({ providedIn: 'root' })
export abstract class BaseAuthViewModel extends BaseViewModel {
  route = inject(ActivatedRoute);
  userDomainModel = inject(UserDomainModel);
  sessionService = inject(SessionService);
  userAPI = inject(UserAPI);
  ngbModal = inject(NgbModal);
  injector = inject(Injector);

  protected constructor(public router: Router, public toastService: ToastService, public cacheService: CacheService) {
    super();
    this.init();
  }

  // Observables
  public abstract nextAuthFlow: Subject<number>;
  public authSuccess: Subject<any> = new Subject<any>();

  private _user: BehaviorSubject<BaseUser | null> = new BehaviorSubject<BaseUser | null>(null);
  public user$ = this._user as Observable<BaseUser | null>;

  private _authFlow = new BehaviorSubject<number>(0);
  public authFlow$ = this._authFlow.pipe(distinctUntilChanged()) as Observable<number>;

  private _currentNavStep = new BehaviorSubject<number>(0);
  public readonly currentNavStep$ = this._currentNavStep.pipe(distinctUntilChanged()) as Observable<number>;

  public eulaValidatorDirective = new EulaRequiredValidatorDirective();

  public eulaErrorMap: Map<string, string> = new Map().set(
    'eulaRequired',
    $localize`Terms and Conditions must be accepted in order to continue`
  );

  public customErrorMap: Map<string, string> = new Map([...this.eulaErrorMap, ...PasswordInputUtils.passwordErrorMap]);

  public abstract authFlowMap: Map<number, number>;

  public abstract readonly authFlowTitle$: Observable<string>;
  public abstract readonly authFlowSubtext$: Observable<string>;

  public abstract readonly eulaText$: Observable<string>;

  public abstract showBackButton$: Observable<boolean>;

  private listenToAuthStep = this.currentNavStep$.subscribeWhileAlive({
    owner: this,
    next: step => {
      const flow = this.authFlowMap?.get(step);
      if (!!flow) this.setAuthFlow(flow);
    }
  });

  // Auth Methods
  // @ts-ignore
  private tapIntoUser(userPipe$: Observable<BaseUser | null>): Observable<BaseUser | null> {
    return userPipe$.pipe(tap(user => this._user.next(user)));
  }

  // Auth Flows

  abstract generateAuthFlowMap(): void;

  abstract goBack(): void;

  public decrementAuthFlow() {
    this.currentNavStep$.once(step => {
      if (step > 0) {
        this._currentNavStep.next(step - 1);
      }
    });
  }

  public incrementAuthFlow() {
    this.currentNavStep$.once(step => {
      this._currentNavStep.next(step + 1);
    });
  }

  public setAuthFlow(flow: number) {
    const key = this.getKeyByValue(flow);
    if (!!key) {
      this._currentNavStep.next(key);
    }
    this._authFlow.next(flow);
  }

  public getCachedSession(): SessionContainer {
    const cachedSession = this.cacheService.getCacheItemString(
      DefaultCacheKey.SessionContainer,
      CachePolicy.Persistent
    );
    return cachedSession ? JSON.parse(cachedSession) : null;
  }

  private getKeyByValue(value: number): number | undefined {
    for (const [key, val] of this.authFlowMap.entries()) {
      if (val === value) {
        return key;
      }
    }
    return undefined;
  }

  public customValueParser = (value: boolean[] | boolean) => {
    if (Array.isArray(value)) {
      return value[0];
    } else {
      return value;
    }
  };

  public openEulaModal(): void {
    this.eulaText$.once(eulaText => {
      ModalEula.open(this.ngbModal, this.injector, eulaText);
    });
  }

  public openContactPage(): void {
    const url = 'https://www.csspen.com/about-us/contact-us';
    window.open(url, '_blank');
  }
}
