import { Deserializable } from '../protocols/deserializable';
import { Mentionable } from '../base/mentionable';
import { LookAheadItem } from '../../views/shared/components/search-with-look-ahead/look-ahead-list/look-ahead-item/protocol/look-ahead-item';

export class RemittanceOwner extends Mentionable implements Deserializable, LookAheadItem {
  lookAheadDisabled(): boolean {
    return false;
  }
  lookAheadDisabledMessage(): string {
    return '';
  }
}
