<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header d-flex flex-row">
      <div class="confirmation-modal-title">
        {{ confirmationOptions?.title }}
      </div>
      <app-close-button (closeClicked)="cancel()"></app-close-button>
    </div>
  </div>

  <div #modalBody class="sticky-body-container">
    <!-- Modal Body -->
    <div
      *ngIf="!confirmationOptions.bodyTextAsHtml"
      ngbAutoFocus
      class="body-text"
      [style.font-size]="confirmationOptions.fontSize">
      {{ confirmationOptions?.bodyText }}
    </div>
    <div
      class="body-text"
      [style.font-size]="confirmationOptions.fontSize"
      *ngIf="confirmationOptions.bodyTextAsHtml"
      ngbAutoFocus
      [innerHTML]="confirmationOptions?.bodyText | trustAsHtml"></div>
  </div>

  <div class="sticky-footer-container modal-footer-flex-end">
    <button
      [style.padding]="'0.75rem 1rem'"
      *ngIf="confirmationOptions?.showCancel"
      (click)="cancel()"
      [ngClass]="confirmationOptions?.cancelButtonClass">
      {{ confirmationOptions?.cancelText }}
    </button>
    <button
      *ngIf="confirmationOptions?.showContinue"
      (click)="continue()"
      [ngClass]="confirmationOptions?.continueButtonClass">
      {{ confirmationOptions?.continueText }}
    </button>
  </div>
</div>
