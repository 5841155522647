import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { DispersedFormGroupService, ReactiveFormItemComponent } from '@csspension/reactive-form';
import { Observable } from 'rxjs';
import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { DesignatedUnitSearchResult } from '../../../../models/employers/plans/designated-unit-search-result';

@Component({
  selector: 'app-designated-unit-search-input',
  templateUrl: './designated-unit-search-input.component.html',
  providers: [
    {
      provide: ReactiveFormItemComponent,
      useExisting: forwardRef(() => DesignatedUnitSearchInputComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesignatedUnitSearchInputComponent extends ReactiveFormItemComponent implements OnChanges {
  constructor(elementRef: ElementRef, cdr: ChangeDetectorRef, dispersedFormGroupService: DispersedFormGroupService) {
    super(elementRef, dispersedFormGroupService, cdr);
  }

  @Input() odataFunction!: (searchString: string) => Observable<DesignatedUnitSearchResult[]>;
  @Input() selectedDesignatedUnit: DesignatedUnitSearchResult | null | undefined;
  @Output() designatedUnitSelected = new EventEmitter<DesignatedUnitSearchResult>();

  public getValidators(): ValidatorFn[] {
    return this.required ? [Validators.required] : [];
  }

  public getAsyncValidators(): AsyncValidatorFn[] {
    return [];
  }

  public setBindingProperty(): void {
    this.setDataInBindingProperty(this.getMyValue());
  }

  private markAsDirty(): void {
    this?.getSelfAsFormItem()?.markAsDirty();
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.selectedDesignatedUnit) {
      this.handleSelectedDesignatedUnitChange(this.selectedDesignatedUnit);
    }
  }

  private handleSelectedDesignatedUnitChange(
    selectedDesignatedUnit: DesignatedUnitSearchResult | null | undefined
  ): void {
    this.handleInputChange(selectedDesignatedUnit);
    this.getSelfAsFormItem()?.patchValue(selectedDesignatedUnit);
    this.markAsDirty();
  }

  protected checkParentElement(): void {}
}
