import { Deserializable } from '../protocols/deserializable';

export class ContributionSummary implements Deserializable {
  public remittanceId!: number;
  public totalContributionCount!: number;
  public totalEntryCount!: number;
  public totalAmount!: number;
  public entrySummaries!: EntrySummary[];

  onDeserialize() {
    this.entrySummaries = window?.injector?.Deserialize?.arrayOf(EntrySummary, this?.entrySummaries);
  }
}

export class EntrySummary implements Deserializable {
  public entryTypeId!: number;
  public entryType!: string;
  public entryCount!: number;
  public amount!: number;

  onDeserialize() {}
}
