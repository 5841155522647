<app-base-action-modal
  title="Add Personal Information"
  i18n-title
  primaryLabel="Next"
  i18n-primaryLabel
  [hideSecondary]="true"
  [ignoreEscape]="true"
  [hideClose]="true"
  (primaryClicked)="form.submitForm(); coopForm?.submitForm()">
  <app-loading *ngIf="viewModel.isLoading$ | async" [options]="(viewModel.loadingOpts$ | async) ?? undefined">
  </app-loading>
  <lib-reactive-form-group #form (formSubmitted$)="formSubmitted($event)" [bindTo]="req$ | async">
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'firstName'"
        label="First Name"
        i18n-label
        [disabled]="true"
        [bindingProperty]="'firstName'"
        [required]="true">
      </lib-reactive-form-text>
      <lib-reactive-form-text
        [inputName]="'middleName'"
        label="Middle Name"
        i18n-label
        [disabled]="true"
        [bindingProperty]="'middleName'"
        hintText="Optional"
        i18n-hintText>
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'lastName'"
        label="Last Name"
        i18n-label
        [disabled]="true"
        [bindingProperty]="'lastName'"
        [required]="true">
      </lib-reactive-form-text>
      <lib-reactive-form-text
        [inputName]="'preferredName'"
        label="Preferred Name"
        i18n-label
        [bindingProperty]="'preferredName'"
        hintText="Optional"
        i18n-hintText>
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <div class="tooltip-row">
      <img [src]="'assets/icons/grey/CircleQuestion.svg'" alt="" />
      <span [ngbTooltip]="tooltip" placement="top" i18n>Information is incorrect</span>
    </div>
    <hr />
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'address1'"
        [required]="true"
        label="Address"
        i18n-label
        [bindingProperty]="'address.addressLine1'">
      </lib-reactive-form-text>
      <lib-reactive-form-text
        [inputName]="'address2'"
        label="Address 2"
        i18n-label
        [bindingProperty]="'address.addressLine2'"
        hintText="Optional"
        i18n-hintText>
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'city'"
        label="City"
        i18n-label
        [required]="true"
        [bindingProperty]="'address.city'">
      </lib-reactive-form-text>
      <lib-reactive-form-drop-down
        [inputName]="'province'"
        label="Province"
        i18n-label
        [inlineLabel]="false"
        placeholder="Select Province"
        i18n-placeholder
        [required]="true"
        [dropdowns]="(viewModel.stateDropdowns$ | async) ?? undefined"
        [bindingProperty]="'address.stateId'">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-drop-down
        [inputName]="'country'"
        label="Country"
        i18n-label
        [inlineLabel]="false"
        [required]="true"
        [dropdowns]="(viewModel.countryDropdowns$ | async) ?? undefined"
        [bindingProperty]="'address.countryId'"
        [programmaticallyChangeValue]="viewModel.selectedCountryId$ | async"
        (valueChanged)="viewModel.countryChanged($event)">
      </lib-reactive-form-drop-down>
      <lib-reactive-form-text
        [inputName]="'postal'"
        [required]="true"
        label="Postal Code"
        i18n-label
        [bindingProperty]="'address.postalCode'">
      </lib-reactive-form-text>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-phone-country-code
        [bindingProperty]="'primaryPhoneNumber'"
        [countryCodeBindingProperty]="'primaryPhoneNumberCountryCode'"
        label="Primary Phone Number"
        i18n-label
        [countryCodes]="(countryCodes$ | async) ?? []"
        [required]="true"
        [inputName]="'primaryPhoneNumber'"></lib-reactive-form-phone-country-code>
      <lib-reactive-form-phone-country-code
        [bindingProperty]="'secondaryPhoneNumber'"
        [countryCodeBindingProperty]="'secondaryPhoneNumberCountryCode'"
        [countryCodes]="(countryCodes$ | async) ?? []"
        label="Secondary Phone Number"
        i18n-label
        hintText="Optional"
        i18n-hintText
        [inputName]="'secondaryPhoneNumber'"></lib-reactive-form-phone-country-code>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-drop-down
        [inputName]="'gender'"
        label="Gender"
        i18n-label
        [required]="true"
        [inlineLabel]="false"
        placeholder="Select gender"
        i18n-placeholder
        [dropdowns]="(viewModel.genderDropdowns$ | async) ?? undefined"
        [bindingProperty]="'genderId'">
      </lib-reactive-form-drop-down>
    </lib-reactive-form-column-layout>
    <hr class="coop-divider" />
    <lib-reactive-form-checkbox
      [inputName]="'workedAtCoop'"
      (valueChanged)="previouslyWorkedForCoopChanged($event)"
      [ngClass]="{ 'mb-4': previouslyWorkedForCoop$ | async }"
      class="d-flex">
      <ng-container i18n>I've previously worked at a co-operative or credit union</ng-container>
    </lib-reactive-form-checkbox>
  </lib-reactive-form-group>
  <!-- TODO: Implement -->
  <lib-reactive-form-group
    *ngIf="previouslyWorkedForCoop$ | async"
    #coopForm
    (formSubmitted$)="coopFormSubmitted($event)">
    <lib-reactive-form-text
      [inputName]="'workName'"
      label="Name"
      i18n-label
      [bindingProperty]="'firstName'"
      [required]="true">
    </lib-reactive-form-text>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-masked-input
        [inputName]="'from'"
        label="From"
        i18n-label
        [mask]="viewModel.dateMask"
        [required]="true"
        [bindingProperty]="'from'">
      </lib-reactive-form-masked-input>
      <lib-reactive-form-masked-input
        [inputName]="'to'"
        label="To"
        i18n-label
        [mask]="viewModel.dateMask"
        [required]="true"
        [bindingProperty]="'to'">
      </lib-reactive-form-masked-input>
    </lib-reactive-form-column-layout>
    <lib-reactive-form-column-layout [nColumns]="(screenService.isMobile$ | async) ? 1 : 2">
      <lib-reactive-form-text
        [inputName]="'workCity'"
        label="City/Town"
        i18n-label
        [required]="true"
        [bindingProperty]="'workCity'"></lib-reactive-form-text>
    </lib-reactive-form-column-layout>
  </lib-reactive-form-group>
</app-base-action-modal>
<ng-template #tooltip>
  <div class="tooltip-title" i18n>Incorrect Information</div>
  <div class="tooltip-body">
    <ng-container i18n
      >You may request a name change through the Member Portal once your account is created.</ng-container
    >
  </div>
</ng-template>
