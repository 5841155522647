import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-simple-pill',
  templateUrl: './simple-pill.component.html',
  styleUrls: ['./simple-pill.component.scss']
})
export class SimplePillComponent {
  @Input() text!: string;
  @Input() imageSrc!: string;
  @Input() flexDirection: string = 'row';
  @Input() disabled: boolean = false;
}
