<app-dark-back-button
  *ngIf="viewModel.showBackButton$ | async"
  (backClicked)="viewModel.goBack()"
  [paddingBottomRem]="2"></app-dark-back-button>
<ng-container [ngSwitch]="viewModel.authFlow$ | async">
  <!--  Sign In Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.SignIn">
    <app-sign-in (loadingOpts)="loadingOpts.emit($event)"></app-sign-in>
  </ng-container>
  <!--  Forgot Password Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.ForgotPassword">
    <app-forgot-password (loadingOpts)="loadingOpts.emit($event)"></app-forgot-password>
  </ng-container>
  <!--  Reset Password Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.ResetPassword">
    <app-reset-password (loadingOpts)="loadingOpts.emit($event)"></app-reset-password>
  </ng-container>
  <!--  New Password Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.EmployerChoosePassword">
    <app-employer-choose-password></app-employer-choose-password>
  </ng-container>
  <!--  Enter MFA Code Flow  -->
  <ng-container *ngSwitchCase="AuthFlow.EmployerMFAVerification">
    <app-verify-mfa (loadingOpts)="loadingOpts.emit($event)"></app-verify-mfa>
  </ng-container>
</ng-container>
