import { inject, Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Remittance } from '../models/remittances/remittance';
import { Endpoints } from './endpoints';
import { CreateRemittanceRequest } from '../models/remittances/create-remittance-request';
import { catchError, map } from 'rxjs/operators';
import { CustomError } from '../models/shared/custom-error';
import { ODataQueryOptions } from '../models/shared/odata-query-options';
import { ODataResponse } from '../models/protocols/odata-response';
import { ContributionEntry } from '../models/remittances/contribution-entry';
import { Contribution } from '../models/remittances/contribution';
import { AddContributionRequest } from '../models/account/requests/add-contribution-request';
import { PortalService } from '../services/portal/portal.service';
import { RemittanceStatusUpdateRequest } from '../models/account/requests/remittance-status-update-request';
import { RemittanceMember } from '../models/remittances/remittance-member';
import { RemittanceHistory } from '../models/remittances/remittance-history';
import { RemittanceAddDatesRequest } from '../models/remittances/remittance-add-dates-request';
import { RemittanceVerificationMember } from '../models/remittances/remittance-verification-member';
import { VerifyContributionRequest } from '../models/remittances/verify-contribution-request';
import { MemberUser } from '../models/account/dto/member-user';
import { ContributionSummary } from '../models/remittances/contribution-summary';
import { RemittanceNewMember } from '../models/remittances/remittance-new-member';
import { RemittanceCreateNewMemberRequest } from '../models/remittances/remittance-create-new-member-request';
import { RemittanceUpdateNewMemberRequest } from '../models/remittances/remittance-update-new-member-request';
import { UpdateContributionRequest } from '../models/remittances/update-contribution-request';
import { RemittanceOwner } from '../models/remittances/remittance-owner';

@Injectable({
  providedIn: 'root'
})
export class RemittancesAPI implements LoggableAPI {
  private apiClient = inject(ApiClient);
  private portalService = inject(PortalService);

  public serviceName = 'RemittancesAPI';

  public getAllRemittances(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<Remittance>> {
    const url = Endpoints.getAllRemittances();
    odataQueryOptions.setExpand('Type, Status, PaymentStatus, Source, Owner');
    return this.apiClient.getOdata(url, Remittance, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getClosedRemittancesForEmployer(
    employerId: number,
    odataParams: ODataQueryOptions
  ): Observable<ODataResponse<Remittance>> {
    const url = Endpoints.getClosedRemittancesForEmployer(employerId);
    odataParams.setExpand('Type, Status, PaymentStatus, Source, Owner');

    return this.apiClient.getOdata(url, Remittance, odataParams).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getOpenRemittancesForEmployer(employerId: number): Observable<ODataResponse<Remittance>> {
    const url = Endpoints.getOpenRemittancesForEmployer(employerId);
    const oDataQueryOptions = new ODataQueryOptions();
    oDataQueryOptions.setExpand('Type, Status, PaymentStatus, Source, Owner, Transactions($expand=Status)');

    return this.apiClient.getOdata(url, Remittance, oDataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceById(remittanceId: number, queryOptions: ODataQueryOptions): Observable<Remittance> {
    const url = Endpoints.getRemittanceById(remittanceId);
    return this.apiClient.getOdataObj(url, Remittance, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createRemittance(req: CreateRemittanceRequest): Observable<Remittance> {
    const url = Endpoints.createRemittance();
    return this.apiClient.postObj(Remittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public submitRemittance(employerId: number, remittanceId: number): Observable<Remittance> {
    const url = Endpoints.submitRemittance(employerId, remittanceId);
    return this.apiClient.putObj(Remittance, url, {} as Remittance).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateRemittanceStatus(
    employerId: number,
    remittanceId: number,
    req: RemittanceStatusUpdateRequest
  ): Observable<Remittance> {
    const url = Endpoints.updateRemittanceStatus(employerId, remittanceId);
    return this.apiClient.putObj(Remittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addDatesToRemittance(remittanceId: number, req: RemittanceAddDatesRequest): Observable<Remittance> {
    const url = Endpoints.addDatesToRemittance(remittanceId);
    return this.apiClient.putObj(Remittance, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteRemittance(employerId: number, remittanceId: number): Observable<any> {
    const url = Endpoints.deleteRemittance(employerId, remittanceId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getMembersOnRemittance(
    employerId: number,
    remittanceId: number,
    searchString?: string
  ): Observable<ODataResponse<RemittanceMember>> {
    const url = Endpoints.getMembersOnRemittance(employerId, remittanceId);
    const queryOptions = new ODataQueryOptions();
    if (searchString) {
      queryOptions.setFilter(`contains(FullName, '${searchString}')`);
    }

    return this.apiClient.getOdata(url, RemittanceMember, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getNewMembersOnRemittance(remittanceId: number): Observable<ODataResponse<RemittanceNewMember>> {
    const url = Endpoints.getNewMembersOnRemittance(remittanceId);
    const queryOptions = new ODataQueryOptions();
    queryOptions.setCount(true);
    queryOptions.setExpand('NewMemberDetails($expand=EmploymentType, ProvinceOfEmployment)');
    return this.apiClient.getOdata(url, RemittanceNewMember, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceOwners(odataQueryOptions: ODataQueryOptions): Observable<ODataResponse<RemittanceOwner>> {
    const url = Endpoints.getRemittanceOwners();
    return this.apiClient.getOdata(url, RemittanceOwner, odataQueryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionsForRemittance(
    remittanceId: number,
    queryOptions: ODataQueryOptions
  ): Observable<ODataResponse<Contribution>> {
    const url = Endpoints.getContributionsForRemittance(remittanceId);
    queryOptions.setExpand('NewMemberDetails, Entries($expand=Code;$count=true)');
    return this.apiClient.getOdata(url, Contribution, queryOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public addContribution(remittanceId: number, req: AddContributionRequest): Observable<Contribution> {
    const url = Endpoints.addContributionToRemittance(remittanceId);
    return this.apiClient.postObj(Contribution, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateContribution(
    remittanceId: number,
    contributionId: string,
    req: UpdateContributionRequest
  ): Observable<Contribution> {
    const url = Endpoints.updateContribution(remittanceId, contributionId);
    return this.apiClient.putObj(Contribution, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteContribution(remittanceId: number, contributionId: string): Observable<any> {
    const url = Endpoints.deleteContribution(remittanceId, contributionId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createContributionEntry(
    remittanceId: number,
    contributionId: string,
    entries: ContributionEntry[]
  ): Observable<Contribution> {
    const url = Endpoints.createContributionEntry(remittanceId, contributionId);
    return this.apiClient.postObj(Contribution, url, entries).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateContributionEntry(
    remittanceId: number,
    contributionId: string,
    entry: any
  ): Observable<ContributionEntry> {
    const url = Endpoints.updateContributionEntry(remittanceId, contributionId, entry.id);
    return this.apiClient.putObj(ContributionEntry, url, entry).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public deleteContributionEntry(remittanceId: number, contributionId: string, entryId: string): Observable<any> {
    const url = Endpoints.deleteContributionEntry(remittanceId, contributionId, entryId);
    return this.apiClient.deleteWithUntypedRes(url).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public createRemittanceNewMember(
    remittanceId: number,
    req: RemittanceCreateNewMemberRequest
  ): Observable<RemittanceNewMember> {
    const url = Endpoints.createRemittanceNewMember(remittanceId, req.contributionId);
    return this.apiClient.postObj(RemittanceNewMember, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public updateRemittanceNewMember(
    remittanceId: number,
    req: RemittanceUpdateNewMemberRequest
  ): Observable<RemittanceNewMember> {
    const url = Endpoints.updateRemittanceNewMember(remittanceId, req.contributionId, req.id);
    return this.apiClient.putObj(RemittanceNewMember, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceHistory(remittanceId: number): Observable<RemittanceHistory[]> {
    const url = Endpoints.getRemittanceHistory(remittanceId);
    return this.apiClient.getOdata(url, RemittanceHistory).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionHistory(remittanceId: string, contributionId: string): Observable<RemittanceHistory[]> {
    const url = Endpoints.getHistoryForContribution(remittanceId, contributionId);
    return this.apiClient.getOdata(url, RemittanceHistory).pipe(
      map(res => res.value),
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getRemittanceMembersToVerify(remittanceId: string): Observable<ODataResponse<RemittanceVerificationMember>> {
    const odataOptions = new ODataQueryOptions();
    odataOptions.setExpand('PotentialMatches');
    const url = Endpoints.getRemittanceMembersToVerify(remittanceId);
    return this.apiClient.getOdata(url, RemittanceVerificationMember, odataOptions).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public verifyContribution(
    remittanceId: string,
    contributionId: string,
    req: VerifyContributionRequest
  ): Observable<MemberUser> {
    const url = Endpoints.verifyContribution(remittanceId, contributionId);
    return this.apiClient.putObj(MemberUser, url, req).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }

  public getContributionSummaryForRemittance(remittanceId: string): Observable<ContributionSummary> {
    const url = Endpoints.getContributionSummaryForRemittance(remittanceId);
    return this.apiClient.getOdataObj(url, ContributionSummary).pipe(
      catchError(e => {
        const err = new CustomError(e, this.serviceName);
        return throwError(() => err);
      })
    );
  }
}
