<div class="auth-flow-subtext">
  <div class="header" i18n>Authenticator App</div>
  <div class="subtext">
    <ng-container i18n>Scan the QR code below using the authenticator app of your choice such as</ng-container>
    <span class="auth-highlighted-text"><ng-container i18n>Google Authenticator</ng-container></span
    >, <span class="auth-highlighted-text"><ng-container i18n>Authy</ng-container></span
    >,
    <ng-container i18n>or</ng-container>
    <span class="auth-highlighted-text"><ng-container i18n>Windows Phone Authenticator</ng-container></span
    >.
  </div>
  <ng-container *ngIf="viewModel.isMobile$ | async">
    <div class="subtext pt-3">
      <ng-container i18n>On Mobile? Click</ng-container>
      <a class="paddingless-text-button copy-text" (click)="clipboardService.copyToClipboard(mfaKey)">
        <ng-container i18n>here</ng-container>
      </a>
      <ng-container i18n>to copy the code to your clipboard.</ng-container>
    </div>
    <hr />
  </ng-container>
</div>
<div>
  <canvas id="qr-code"></canvas>
  <button class="paddingless-text-button qr-support" (click)="openSupportModal()">
    <ng-container i18n>QR Code Support</ng-container>
  </button>
</div>

<div class="subtext pb-3" i18n>
  After setting up your authenticator, you will be given a six-digit verification code. Please enter it below.
</div>

<lib-reactive-form-group #form [bindTo]="req" (formSubmitted$)="formSubmitted(req)">
  <lib-reactive-form-string-number
    [inputName]="'timeBasedOneTimePassword'"
    label="Verification Code"
    i18n-label
    [inlineLabel]="false"
    [placeholder]="''"
    [integersOnly]="true"
    [required]="true"
    [minCharacterCount]="6"
    [maxCharacterCount]="6"
    [showRequiredAstrix]="false"
    [bindingProperty]="'timeBasedOneTimePassword'">
  </lib-reactive-form-string-number>
</lib-reactive-form-group>
<lib-button-primary class="w-100 mt-3" (buttonClicked)="form.submitForm()">
  <ng-container i18n>Submit</ng-container>
</lib-button-primary>
<div class="support-button-container">
  <span i18n>Having trouble?</span>
  <button class="paddingless-text-button mt-2" (click)="viewModel.openContactPage()">
    <ng-container i18n>Click here for support</ng-container>
  </button>
</div>
