import { inject, Injectable } from '@angular/core';
import { TypeService } from '../../../../services/type/type-service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UpdateContactInfoRequest } from '../../../../models/account/requests/submissionRequests/update-contact-info-request';
import { PhoneNumberUtils } from '../../../../utils/phone-number-utils';
import { AbstractSubmissionFormViewModel } from '../submission-forms/abstract-submission-form/abstract-submission-form-view-model';
import { MemberUser } from '../../../../models/account/dto/member-user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Injectable()
// eslint-disable-next-line max-len
export class UpdateContactInfoSubmissionFormViewModel extends AbstractSubmissionFormViewModel<UpdateContactInfoRequest> {
  constructor(router: Router, ngbModal: NgbModal) {
    super(router, ngbModal);
  }

  private typeService = inject(TypeService);

  public countryCodes$ = this.typeService.countryCodes$;

  protected createSubmissionMethod = (req: UpdateContactInfoRequest) =>
    this.submissionsDomainModel.createUpdateContactInfoSubmission(req);

  protected successMessage = $localize`Update Contact Information Request Submitted`;

  private _user = new BehaviorSubject<MemberUser | null>(null);
  public user$ = this._user as Observable<MemberUser | null>;
  public setUser = (user: MemberUser) => this._user.next(user);

  public req$ = combineLatest([this.countryCodes$, this.user$]).pipe(
    map(([countryCodes, user]) => {
      if (!user) return;
      // Remove prefix from phone numbers for the dropdown
      if (user.primaryPhoneNumber) {
        user.primaryPhoneNumber = PhoneNumberUtils.removeCodePrefix(
          user.primaryPhoneNumber,
          user.primaryPhoneNumberCountryCode,
          countryCodes
        );
      }
      if (user.secondaryPhoneNumber) {
        user.secondaryPhoneNumber = PhoneNumberUtils.removeCodePrefix(
          user.secondaryPhoneNumber,
          user.secondaryPhoneNumberCountryCode,
          countryCodes
        );
      }

      return new UpdateContactInfoRequest(user);
    })
  );
}
