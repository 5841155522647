<div class="auth-flow-subtext">
  <div class="header">{{ viewModel.authFlowTitle$ | async }}</div>
  <div class="subtext">{{ viewModel.authFlowSubtext$ | async }}</div>
</div>

<lib-reactive-form-group #form [bindTo]="req" (formSubmitted$)="formSubmitted(req)">
  <lib-reactive-form-string-number
    [inputName]="'timeBasedOneTimePassword'"
    label="Verification Code"
    i18n-label
    [inlineLabel]="false"
    [placeholder]="''"
    [integersOnly]="true"
    [required]="true"
    [minCharacterCount]="6"
    [maxCharacterCount]="6"
    [showRequiredAstrix]="false"
    [bindingProperty]="'timeBasedOneTimePassword'">
  </lib-reactive-form-string-number>
</lib-reactive-form-group>

<lib-button-primary class="w-100" (buttonClicked)="form.submitForm()">
  <ng-container i18n>Verify Account</ng-container>
</lib-button-primary>
