import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, skip, Subject } from 'rxjs';
import { debounceTime, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BaseViewModel } from '../../../../../../models/base/base-view-model';
import { ToastService } from '../../../../../../services/toast-service';
import { InternalUsersDomainModel } from '../../../../../../domainModels/internal-users-domain-model.service';
import { PermissionService } from '../../../../../../services/permission-service';
import { UserDomainModel } from '../../../../../../domainModels/user-domain-model';
import { PermissionDomainModel } from '../../../../../../domainModels/permission-domain-model';
import { SlidingWindowOptions } from '../../../../../../models/shared/sliding-window-options';
import { InternalUserTableFilterRequest } from '../../../../../../models/shared/odata-filter-requests/internal-user-table-filter-request';
import { InternalUserTableOrderbyRequest } from '../../../../../../models/shared/odata-sorting-requests/internal-user-table-orderby-request';
import { DropDownItem } from '../../../../../../models/shared/stylesheet/drop-down-item';
import { InternalUser } from '../../../../../../models/account/dto/internal-user';
import { OdataFilterGenerator } from '../../../../../../interfaces/odata-filter-generator';
import { DateUtils } from '../../../../../../utils/date-utils';
import { ODataQueryOptions } from '../../../../../../models/shared/odata-query-options';
import { CustomError } from '../../../../../../models/shared/custom-error';
import { StringExtensions } from '../../../../../../utils/string.extensions';

@Injectable()
export class AllInternalUsersDataTableViewModel extends BaseViewModel {
  constructor(private toastService: ToastService, private router: Router) {
    super();
  }

  private domainModel = inject(InternalUsersDomainModel);
  private permissionService = inject(PermissionService);
  private userDomainModel = inject(UserDomainModel);
  private permissionDomainModel = inject(PermissionDomainModel);

  public internalUsers$ = this.domainModel.internalUsersForDatatable$.pipe(skip(1));
  public internalUserTotalCount$ = this.domainModel.allInternalUsersTotalCount$;

  private _showFilterWindow = new BehaviorSubject<boolean>(false);
  public readonly showFilterWindow$ = this._showFilterWindow as Observable<boolean>;

  private _showSearchBars = new BehaviorSubject<boolean>(false);
  public readonly showSearchBars$ = this._showSearchBars as Observable<boolean>;

  private _activeFilterCount = new BehaviorSubject<number>(0);
  public readonly activeFilterCount$ = this._activeFilterCount as Observable<number>;

  private _activeSearchCriteria = new BehaviorSubject<number>(0);
  public readonly activeSearchCriteria$ = this._activeSearchCriteria as Observable<number>;

  public filterWindowOptions$ = of(
    new SlidingWindowOptions($localize`Filters`, $localize`Clear All`, $localize`Update Filters`)
  );

  public roles$ = this.permissionDomainModel.roles$;

  private _loadingInternalUsers = new BehaviorSubject<boolean>(false);
  public loadingInternalUsers$ = this._loadingInternalUsers as Observable<boolean>;

  public showRole$ = this.permissionService.permissionGranted$([5]);
  public showEmail$ = this.permissionService.permissionGranted$([4]);
  public showStatus$ = this.permissionService.permissionGranted$([6]);

  private _internalUserFilterRequest = new BehaviorSubject<InternalUserTableFilterRequest>(
    new InternalUserTableFilterRequest()
  );
  public readonly internalUserFilterRequest$ = this._internalUserFilterRequest
    .distinctUniquelyIdentifiable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private _internalUserOrderbyRequest = new BehaviorSubject<InternalUserTableOrderbyRequest>(
    new InternalUserTableOrderbyRequest()
  );
  public readonly internalUserOrderbyRequest$ = this
    ._internalUserOrderbyRequest as Observable<InternalUserTableOrderbyRequest>;

  private _currPageNumber = new BehaviorSubject<number>(0);
  public readonly currPageNumber$ = this._currPageNumber as Observable<number>;

  private _pageSize = new BehaviorSubject<number>(10);
  public readonly pageSize$ = this._pageSize as Observable<number>;

  private _searchCriteriaChangedSubject = new Subject<InternalUserTableFilterRequest>();
  public readonly searchCriteriaChanged$ = this
    ._searchCriteriaChangedSubject as Observable<InternalUserTableFilterRequest>;

  private _selectedStartDate = new BehaviorSubject<string>('');
  public readonly selectedStartDate$ = this._selectedStartDate as Observable<string>;

  private _selectedEndDate = new BehaviorSubject<string>('');
  public readonly selectedEndDate$ = this._selectedEndDate as Observable<string>;

  private _selectedDates = new BehaviorSubject<NgbDate[]>([]);
  public readonly selectedDates$ = this._selectedDates as Observable<NgbDate[]>;

  public clearDates$ = new Subject<void>();

  public statusDropdowns$ = of([
    new DropDownItem($localize`Active`, 'true', false),
    new DropDownItem($localize`Inactive`, 'false', false)
  ]);

  private searchCriteriaChangedSubscription = this.searchCriteriaChanged$
    .distinctUniquelyIdentifiable()
    .pipe(debounceTime(500))
    .subscribeWhileAlive({
      owner: this,
      next: req => {
        this._internalUserFilterRequest.next(req);
        this._activeSearchCriteria.next(req.getSearchCount());
      }
    });

  private listenToFetchInternalUsers = combineLatest([
    this.internalUserFilterRequest$,
    this.internalUserOrderbyRequest$,
    this.currPageNumber$,
    this.pageSize$
  ])
    .pipe(debounceTime(100))
    .subscribe(([filterRequest, orderbyRequest, currPage, pageSize]) => {
      this.fetchInternalUsers(filterRequest, orderbyRequest, currPage, pageSize);
    });

  public toggleFilterWindow(): void {
    this._showFilterWindow.once(show => {
      this._showFilterWindow.next(!show);
    });
  }

  public toggleSearchBars(): void {
    this._showSearchBars.once(show => {
      this._showSearchBars.next(!show);
    });
  }

  public internalUserClicked(u: InternalUser) {
    this.userDomainModel.userId$.once(uId => {
      if (Number.parseInt(uId, 10) === u?.id) {
        this.router.navigate(['/profile']).then();
      } else {
        this.router.navigate([`settings/internal-users/${u.id}`]).then();
      }
    });
  }

  public filterFormSubmitted(req: InternalUserTableFilterRequest): void {
    const copy = Object.assign(new InternalUserTableFilterRequest(), req);
    this.generateDatesFromFilterRequest(copy);
    this._activeFilterCount.next(copy.getFilterCount());
    this._internalUserFilterRequest.next(copy);
  }

  public searchCriteriaChanged(req: OdataFilterGenerator): void {
    const copy = Object.assign(new InternalUserTableFilterRequest(), req);
    this._searchCriteriaChangedSubject.next(copy as InternalUserTableFilterRequest);
  }

  public resetFilterForm(): void {
    this.internalUserFilterRequest$.once(req => {
      const copy = Object.assign(new InternalUserTableFilterRequest(), req);
      copy.clearFilters();
      this._internalUserFilterRequest.next(copy as InternalUserTableFilterRequest);
      this._activeFilterCount.next(0);
      this._selectedDates.next([]);
      this._selectedStartDate.next('');
      this._selectedEndDate.next('');
      this.clearDates$.next();
    });
  }

  public resetFilterField(fieldKeys: string[]): void {
    this.internalUserFilterRequest$.once(req => {
      const reqCopy = Object.assign(new InternalUserTableFilterRequest(), req);
      fieldKeys.forEach(k => {
        if (k.includes('Date' || 'search')) {
          reqCopy[k] = '';
          this._selectedDates.next([]);
          this._selectedEndDate.next('');
          this._selectedStartDate.next('');
        } else {
          reqCopy[k] = [];
        }
      });
      this._internalUserFilterRequest.next(reqCopy as InternalUserTableFilterRequest);
      this._activeFilterCount.next(reqCopy.getFilterCount());
    });
  }

  public updateCurrPageNumber(currPage: number): void {
    this._currPageNumber.next(currPage);
  }

  public handleSortReq(sortReq: string): void {
    this._internalUserOrderbyRequest.next(new InternalUserTableOrderbyRequest(sortReq));
  }

  public clearAllFilters(): void {
    this._internalUserFilterRequest.next(new InternalUserTableFilterRequest());
    this._activeSearchCriteria.next(0);
    this._activeFilterCount.next(0);
    this._selectedDates.next([]);
    this._selectedStartDate.next('');
    this._selectedEndDate.next('');
    this.clearDates$.next();
  }

  private generateDatesFromFilterRequest(req: InternalUserTableFilterRequest): void {
    const dates = [];
    if (!!req.startDate) {
      dates.push(StringExtensions.convertDateStringToNgbDate(req.startDate));
    }
    if (!!req.endDate) {
      dates.push(StringExtensions.convertDateStringToNgbDate(req.endDate));
    }
    this.setDateFilters(dates);
  }

  public setDateFilters(dates: NgbDate[]): void {
    if (dates.length === 1) {
      this._selectedStartDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
    } else if (dates.length === 2) {
      this._selectedStartDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
      this._selectedEndDate.next(DateUtils.convertNgbDateObjectToString(dates[1]));
    }
    this._selectedDates.next(dates);
  }

  private fetchInternalUsers(
    filterReq: InternalUserTableFilterRequest,
    orderbyReq: InternalUserTableOrderbyRequest,
    currPage: number,
    pageSize: number,
    showSpinner = true
  ): void {
    const lm = $localize`Loading Internal Users`;
    if (showSpinner) {
      this._loadingOpts.addRequest(lm);
    }
    this._loadingInternalUsers.next(true);
    const oDataParams = new ODataQueryOptions();
    const filterString = filterReq.getFilterString();
    const orderbyString = orderbyReq.getOrderByString();
    if (!!filterString) {
      oDataParams.setFilter(filterString);
    }
    if (!!orderbyString) {
      oDataParams.setOrderBy(orderbyString);
    }
    oDataParams.setCount(true);
    oDataParams.setTop(pageSize);
    oDataParams.setSkip(currPage * pageSize);
    this.domainModel.getInternalUsersForDatatable(oDataParams).subscribe({
      complete: () => {
        this._loadingOpts.removeRequest(lm);
        this._loadingInternalUsers.next(false);
      },
      error: (error: CustomError) => {
        this._loadingOpts.removeRequest(lm);
        this._loadingInternalUsers.next(false);
        this.toastService.publishError(error);
      }
    });
  }

  public refreshInternalUsers(): void {
    combineLatest([
      this.internalUserFilterRequest$,
      this.internalUserOrderbyRequest$,
      this.currPageNumber$,
      this.pageSize$
    ]).once(([filterReq, orderbyReq, currPage, pageSize]) => {
      this.fetchInternalUsers(filterReq, orderbyReq, currPage, pageSize, false);
    });
  }
}
