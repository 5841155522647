import { Deserializable } from '../../protocols/deserializable';
import { Address } from '../dto/address';
import { MemberUser } from '../dto/member-user';
import { Gender } from '../dto/gender';
import { GenderTypeEnum } from '../enum/gender-type';

export class MemberOnboardingInfoRequest implements Deserializable {
  public id: number;
  public firstName: string;
  public middleName: string | null;
  public lastName: string;
  public preferredName: string | null;
  public genderId: GenderTypeEnum;
  public gender: Gender;
  public primaryPhoneNumber: string;
  public primaryPhoneNumberCountryCode?: string;
  public secondaryPhoneNumber: string;
  public secondaryPhoneNumberCountryCode?: string;
  public addressId?: string;
  public address: Address;

  constructor(user: MemberUser) {
    this.id = user.id;
    this.firstName = user.firstName;
    this.middleName = user.middleName;
    this.lastName = user.lastName;
    this.preferredName = user.preferredName;
    this.genderId = user.genderId;
    this.gender = user.gender;
    this.primaryPhoneNumber = user.primaryPhoneNumber;
    this.primaryPhoneNumberCountryCode = user.primaryPhoneNumberCountryCode;
    this.secondaryPhoneNumber = user.secondaryPhoneNumber;
    this.secondaryPhoneNumberCountryCode = user.secondaryPhoneNumberCountryCode;
    this.addressId = user.addressId;
    this.address = user.address ?? new Address();
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.address = Deserialize?.instanceOf(Address, this.address);
  }
}
