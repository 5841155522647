<!-- TODO: CSS-1140 Add shimmer loading -->
<lib-reactive-form-merge-groups #mergedFormGroups [mergeKey]="'editUser'" (formsSubmitted$)="userUpdated.emit($event)">
</lib-reactive-form-merge-groups>
<div class="card-container-title view-user-account-header-padding">
  <app-dark-back-button [paddingBottomRem]="0.5" (backClicked)="goBack()"></app-dark-back-button>
  <span class="header">{{ headerText }}</span>
</div>
<div class="card-container view-user-account-card-container-margin">
  <app-loading *ngIf="loadingOpts.isLoading" [options]="loadingOpts ?? undefined"></app-loading>
  <div class="card-form-content-container">
    <div class="card-form-container">
      <!-- Information -->
      <app-base-card [height]="'100%'" class="info-card">
        <lib-reactive-form-group [bindTo]="user" [permissionMap]="permissionMap ?? undefined" [mergeKey]="'editUser'">
          <span [style.padding-bottom.rem]="2" class="card-header" i18n>Information</span>
          <lib-reactive-form-column-layout nColumns="2">
            <lib-reactive-form-text
              [inputName]="'firstName'"
              [permissionFieldId]="fieldIdPrefix + 'FirstName'"
              [required]="true"
              label="First Name"
              i18n-label
              [bindingProperty]="'firstName'">
            </lib-reactive-form-text>
            <lib-reactive-form-text
              [inputName]="'lastName'"
              [permissionFieldId]="fieldIdPrefix + 'LastName'"
              [required]="true"
              label="Last Name"
              i18n-label
              [bindingProperty]="'lastName'">
            </lib-reactive-form-text>
            <lib-reactive-form-text
              [inputName]="'email'"
              [permissionFieldId]="fieldIdPrefix + 'Email'"
              [required]="true"
              [disabled]="true"
              label="Email"
              i18n-label
              [bindingProperty]="'email'">
            </lib-reactive-form-text>
            <lib-reactive-form-drop-down
              [inputName]="'status'"
              [permissionFieldId]="fieldIdPrefix + 'Status'"
              label="Status"
              i18n-label
              [inlineLabel]="false"
              [required]="true"
              [dropdowns]="statusDropdowns ?? undefined"
              [bindingProperty]="'active'">
            </lib-reactive-form-drop-down>
          </lib-reactive-form-column-layout>
          <hr class="pb-2" />
        </lib-reactive-form-group>
        <app-add-remove-role-form
          *ngIf="canViewUserRoles"
          [permissionMap]="permissionMap ?? undefined"
          [mergeKey]="'editUser'"
          [permissionFieldId]="fieldIdPrefix + 'Role'"
          [user]="user ?? undefined"
          [addRoleMap]="userRoleMap ?? undefined">
        </app-add-remove-role-form>
        <div *ngIf="userHasNotLoggedIn; else timestamp" class="flex-container flex-align-center">
          <div class="account-timestamp" i18n>User hasn't logged in yet.</div>
          <div
            *ngIf="canResendUserInvite"
            class="primary-paddingless-text-button"
            (click)="resendInvite.emit(true)"
            i18n>
            Resend Invite?
          </div>
        </div>
        <ng-template #timestamp>
          <div class="d-flex flex-column gap-4" *ngIf="isEmployer$ | async">
            <div class="account-timestamp" *ngIf="!!user?.lastLoginDate">
              {{ lastLoginTime }}
            </div>
            <div class="account-timestamp" *ngIf="!user?.active && user?.setToInactiveString">
              {{ user?.setToInactiveString }}
            </div>
          </div>
          <div *ngIf="isInternal$ | async">
            <div class="account-timestamp" *ngIf="user?.active">{{ lastLoginTime }}</div>
            <div class="account-timestamp" *ngIf="!user?.active">
              {{ user?.setToInactiveString }}
            </div>
          </div>
        </ng-template>
      </app-base-card>
    </div>
    <div *ngIf="showActionContainer" class="card-form-action-container">
      <app-base-card [height]="'100%'" [padding]="(isMobile$ | async) ? '2rem 1.5rem' : '2rem'">
        <div class="action-title" i18n>Actions</div>
        <div class="action-items-container">
          <div class="action-item" *ngIf="canChangeUserEmail">
            <img class="action-icon" [src]="'assets/icons/primary/Mail.svg'" alt="" />
            <span class="card-header" (click)="openChangeEmailModal.emit()" i18n>Change Email</span>
          </div>
          <div class="action-item" *ngIf="canResetUserPassword">
            <img class="action-icon" [src]="'assets/icons/primary/Lock On.svg'" alt="" />
            <span class="card-header" (click)="resendInvite.emit(false)" i18n>Reset Password</span>
          </div>
          <div *ngIf="userHasNotLoggedIn && canResendUserInvite" class="action-item">
            <img class="action-icon" [src]="'assets/icons/primary/RefreshLeft.svg'" alt="" />
            <span class="card-header" (click)="resendInvite.emit(true)" i18n>Resend Invite</span>
          </div>
        </div>
      </app-base-card>
    </div>
  </div>
  <ng-content></ng-content>
  <div *ngIf="canEditUser" class="card-container-button-row">
    <lib-button-primary (buttonClicked)="mergedFormGroups.submitForms()" i18n>Save Changes</lib-button-primary>
  </div>
</div>
