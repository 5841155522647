import { OdataFilterGenerator } from '../../../interfaces/odata-filter-generator';
import * as moment from 'moment/moment';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { Deserializable } from '../../protocols/deserializable';

export class MemberSubmissionsFilterRequest implements OdataFilterGenerator, UniquelyIdentifiable, Deserializable {
  public statuses: string[] = [];
  public ownerIds: string[] = [];
  public approverIds: string[] = [];
  public startDate: string = '';
  public endDate: string = '';
  public submissionType: string = '';

  public searchSubName: string = '';
  public searchSubId: string = '';
  public searchMemberId: string = '';
  public searchMemberName: string = '';
  public searchStatus: string | null = '';
  public searchOwner: string = '';
  public searchApprover: string = '';
  public searchSubDate: string = '';

  private filterCount: number = 0;
  private searchCount: number = 0;

  [key: string]: any; // Index signature to allow dynamic property querying

  getFilterString(): string | null {
    const filterArray = [];
    if (this.statuses?.length > 0) {
      const filterConditions = this.statuses.map(statusId => `subStatusId eq ${statusId}`).join(' or ');
      filterArray.push(`(${filterConditions})`);
      this.filterCount++;
    }
    if (this.startDate !== '') {
      const startDate = moment(this.startDate).startOf('day');
      let endDate = startDate.clone().endOf('day');
      if (this.endDate !== '') {
        endDate = moment(this.endDate).endOf('day');
      }
      filterArray.push(
        `submittedDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and submittedDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );

      this.filterCount++;
    }

    if (!!this.submissionType) {
      filterArray.push(`submissionTypeId eq ${this.submissionType}`);
      this.filterCount++;
    }

    if (this.ownerIds?.length > 0) {
      const filterConditions = this.ownerIds.map(ownerId => `ownerId eq ${ownerId}`).join(' or ');
      filterArray.push(`(${filterConditions})`);
      this.filterCount++;
    }
    if (this.approverIds?.length > 0) {
      const filterConditions = this.approverIds.map(approverId => `approverId eq ${approverId}`).join(' or ');
      filterArray.push(`(${filterConditions})`);
      this.filterCount++;
    }
    if (this.searchSubDate !== '') {
      const startDate = moment(this.searchSubDate).startOf('day');
      const endDate = startDate.clone().endOf('day');
      filterArray.push(
        `submittedDate ge cast(${startDate.toISOString(
          true
        )}, Edm.DateTimeOffset) and submittedDate le cast(${endDate.toISOString(true)}, Edm.DateTimeOffset)`
      );
      this.searchCount++;
    }
    if (this.searchSubName !== '') {
      filterArray.push(`contains(submissionTypeName, '${this.searchSubName}')`);
      this.searchCount++;
    }
    if (this.searchSubId !== '') {
      // Removing all non-numerical characters from the search string
      const cleanedSearchSubId = this.searchSubId.replace(/\D/g, '');
      if (cleanedSearchSubId !== '') {
        filterArray.push(`(id eq ${cleanedSearchSubId})`);
        this.searchCount++;
      }
    }
    if (this.searchMemberId !== '') {
      filterArray.push(`(memberId eq ${this.searchMemberId})`);
      this.searchCount++;
    }
    if (this.searchMemberName !== '') {
      filterArray.push(`contains(memberName, '${this.searchMemberName}')`);
      this.searchCount++;
    }
    if (this.searchStatus !== '' && this.searchStatus !== null) {
      filterArray.push(`(subStatusId eq ${this.searchStatus})`);
      this.searchCount++;
    }
    if (this.searchOwner !== '') {
      filterArray.push(
        `contains(owner/firstname, '${this.searchOwner}') or contains(owner/lastname, '${this.searchOwner}')`
      );
      this.searchCount++;
    }
    if (this.searchApprover !== '') {
      filterArray.push(
        `contains(approver/firstname, '${this.searchApprover}')` +
          `or contains(approver/lastname, '${this.searchApprover}')`
      );
      this.searchCount++;
    }
    if (filterArray.length > 1) {
      return filterArray.join(' and ');
    } else if (filterArray.length === 1) {
      return filterArray[0];
    } else {
      return null;
    }
  }

  public getFilterCount(): number {
    this.filterCount = 0;
    this.getFilterString();
    return this.filterCount;
  }

  public getSearchCount(): number {
    this.searchCount = 0;
    this.getFilterString();
    return this.searchCount;
  }

  public clearFilters(): void {
    this.statuses = [];
    this.ownerIds = [];
    this.approverIds = [];
    this.startDate = '';
    this.endDate = '';
    this.submissionType = '';
  }

  getUniqueIdentifier(): string {
    return `
    ${this.statuses.join(',')} +
    ${this.ownerIds.join(',')} +
    ${this.approverIds.join(',')} +
    ${this.startDate} +
    ${this.endDate} +
    ${this.submissionType} +
    ${this.searchSubName} +
    ${this.searchSubId} +
    ${this.searchMemberId} +
    ${this.searchMemberName} +
    ${this.searchStatus} +
    ${this.searchOwner} +
    ${this.searchApprover} +
    ${this.searchSubDate} +
    ${this.filterCount} +
    ${this.searchCount}`;
  }

  onDeserialize(): void {}
}
