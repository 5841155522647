import { Injectable } from '@angular/core';
import { ChangeNameRequest } from '../../../../models/account/requests/submissionRequests/change-name-request';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractSubmissionFormViewModel } from '../submission-forms/abstract-submission-form/abstract-submission-form-view-model';

@Injectable({ providedIn: 'root' })
export class NameChangeSubmissionFormViewModel extends AbstractSubmissionFormViewModel<ChangeNameRequest> {
  constructor(public router: Router, public modalService: NgbModal) {
    super(router, modalService);
  }
  protected createSubmissionMethod = (req: ChangeNameRequest) =>
    this.submissionsDomainModel.createMemberNameChangeSubmission(req);

  protected successMessage = $localize`Name Change Request Submitted`;
}
