import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, shareReplay } from 'rxjs/operators';
import { ClosedRemittanceTableOrderbyRequest } from '../../../../../models/shared/odata-sorting-requests/closed-remittance-table-orderby-request';
import { OdataFilterGenerator } from '../../../../../interfaces/odata-filter-generator';
import { ClosedRemittanceTableFilterRequest } from '../../../../../models/shared/odata-filter-requests/closed-remittance-table-filter-request';
import { TypeService } from '../../../../../services/type/type-service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from '../../../../../utils/date-utils';
import { Remittance } from '../../../../../models/remittances/remittance';
import { RemittanceDomainModel } from '../../../../../domainModels/remittance-domain-model';
import { ODataQueryOptions } from '../../../../../models/shared/odata-query-options';
import { CustomError } from '../../../../../models/shared/custom-error';
import { ToastService } from '../../../../../services/toast-service';

@Injectable({
  providedIn: 'root'
})
export class ClosedRemittanceTableViewModel extends BaseViewModel {
  private typeService = inject(TypeService);
  private remittanceDomainModel = inject(RemittanceDomainModel);
  private toastService = inject(ToastService);

  private _remittances = new BehaviorSubject<Remittance[] | null>(null);
  public remittances$ = this._remittances as Observable<Remittance[] | null>;

  private _remittanceTotalCount = new BehaviorSubject<number | undefined>(undefined);
  public remittanceTotalCount$ = this._remittanceTotalCount as Observable<number | undefined>;

  public remittanceStatuses$ = this.typeService.remittanceStatusTypes$;

  private _showFilterWindow = new BehaviorSubject<boolean>(false);
  public readonly showFilterWindow$ = this._showFilterWindow as Observable<boolean>;

  private _showSearchBars = new BehaviorSubject<boolean>(false);
  public readonly showSearchBars$ = this._showSearchBars as Observable<boolean>;

  private _activeFilterCount = new BehaviorSubject<number>(0);
  public readonly activeFilterCount$ = this._activeFilterCount as Observable<number>;

  private _activeSearchCriteria = new BehaviorSubject<number>(0);
  public readonly activeSearchCriteria$ = this._activeSearchCriteria as Observable<number>;

  private _currPageNumber = new BehaviorSubject<number>(0);
  public readonly currPageNumber$ = this._currPageNumber as Observable<number>;

  private _pageSize = new BehaviorSubject<number>(10);
  public readonly pageSize$ = this._pageSize as Observable<number>;

  private _closedRemittancesTableFilterRequest = new BehaviorSubject<ClosedRemittanceTableFilterRequest>(
    new ClosedRemittanceTableFilterRequest()
  );
  public readonly closedRemittancesTableFilterRequest$ = this._closedRemittancesTableFilterRequest
    .distinctUniquelyIdentifiable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private _closedRemittancesTableOrderByRequest = new BehaviorSubject<ClosedRemittanceTableOrderbyRequest>(
    new ClosedRemittanceTableOrderbyRequest()
  );
  public readonly closedRemittancesTableOrderByRequest$ = this
    ._closedRemittancesTableOrderByRequest as Observable<ClosedRemittanceTableOrderbyRequest>;

  private _searchCriteriaChangedSubject = new Subject<ClosedRemittanceTableFilterRequest>();
  public readonly searchCriteriaChanged$ = this
    ._searchCriteriaChangedSubject as Observable<ClosedRemittanceTableFilterRequest>;

  private _loadingRemittances = new BehaviorSubject<boolean>(false);
  public loadingRemittances$ = this._loadingRemittances as Observable<boolean>;

  // Filter Date Pickers

  private _lastStatusUpdateStartDate = new BehaviorSubject<string>('');
  public readonly lastStatusUpdateStartDate$ = this._lastStatusUpdateStartDate as Observable<string>;

  private _lastStatusUpdateEndDate = new BehaviorSubject<string>('');
  public readonly lastStatusUpdateEndDate$ = this._lastStatusUpdateEndDate as Observable<string>;

  private _lastStatusUpdateDates = new BehaviorSubject<NgbDate[]>([]);
  public readonly lastStatusUpdateDates$ = this._lastStatusUpdateDates as Observable<NgbDate[]>;

  private _payPeriodStartDate = new BehaviorSubject<string>('');
  public readonly payPeriodStartDate$ = this._payPeriodStartDate as Observable<string>;

  private _payPeriodEndDate = new BehaviorSubject<string>('');
  public readonly payPeriodEndDate$ = this._payPeriodEndDate as Observable<string>;

  private _payPeriodDates = new BehaviorSubject<NgbDate[]>([]);
  public readonly payPeriodDates$ = this._payPeriodDates as Observable<NgbDate[]>;

  private _totalRemittanceStartAmount = new BehaviorSubject<string>('');
  public readonly totalRemittanceStartAmount$ = this._totalRemittanceStartAmount as Observable<string>;

  private _totalRemittanceEndAmount = new BehaviorSubject<string>('');
  public readonly totalRemittanceEndAmount$ = this._totalRemittanceEndAmount as Observable<string>;

  public clearDates$ = new Subject<void>();

  private listenToFetchRemittances = combineLatest([
    this.closedRemittancesTableFilterRequest$,
    this.closedRemittancesTableOrderByRequest$,
    this.currPageNumber$,
    this.pageSize$
  ])
    .pipe(debounceTime(100))
    .subscribeWhileAlive({
      owner: this,
      next: ([filterRequest, orderbyRequest, currPage, pageSize]) => {
        this.fetchClosedRemittances(filterRequest, orderbyRequest, currPage, pageSize);
      }
    });

  private searchCriteriaChangedSubscription = this.searchCriteriaChanged$
    .distinctUniquelyIdentifiable()
    .pipe(debounceTime(500))
    .subscribeWhileAlive({
      owner: this,
      next: req => {
        this._closedRemittancesTableFilterRequest.next(req);
        this._activeSearchCriteria.next(req.getSearchCount());
      }
    });

  public handleSortReq(sortReq: string): void {
    this._closedRemittancesTableOrderByRequest.next(new ClosedRemittanceTableOrderbyRequest(sortReq));
  }

  public searchCriteriaChanged(req: OdataFilterGenerator): void {
    const copy = Object.assign(new ClosedRemittanceTableFilterRequest(), req);
    this._searchCriteriaChangedSubject.next(copy as ClosedRemittanceTableFilterRequest);
  }

  public filterFormSubmitted(req: ClosedRemittanceTableFilterRequest): void {
    const copy = Object.assign(new ClosedRemittanceTableFilterRequest(), req);
    this._activeFilterCount.next(copy.getFilterCount());
    this._closedRemittancesTableFilterRequest.next(copy);
  }

  public toggleFilterWindow(): void {
    this._showFilterWindow.once(show => {
      this._showFilterWindow.next(!show);
    });
  }

  public toggleSearchBars(): void {
    this._showSearchBars.once(show => {
      this._showSearchBars.next(!show);
    });
  }

  public updateCurrPageNumber(currPage: number): void {
    this._currPageNumber.next(currPage);
  }

  public setLastStatusUpdateDateFilters(dates: NgbDate[]): void {
    if (dates.length === 1) {
      this._lastStatusUpdateStartDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
    } else if (dates.length === 2) {
      this._lastStatusUpdateStartDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
      this._lastStatusUpdateEndDate.next(DateUtils.convertNgbDateObjectToString(dates[1]));
    }
    this._lastStatusUpdateDates.next(dates);
  }

  public setPayPeriodDateFilters(dates: NgbDate[]): void {
    if (dates.length === 1) {
      this._payPeriodStartDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
    } else if (dates.length === 2) {
      this._payPeriodStartDate.next(DateUtils.convertNgbDateObjectToString(dates[0]));
      this._payPeriodEndDate.next(DateUtils.convertNgbDateObjectToString(dates[1]));
    }
    this._payPeriodDates.next(dates);
  }

  public resetFilterField(fieldKeys: string[]): void {
    this.closedRemittancesTableFilterRequest$.once(req => {
      const reqCopy = Object.assign(new ClosedRemittanceTableFilterRequest(), req);
      fieldKeys.forEach(k => {
        if (k.includes('Date' || 'search')) {
          reqCopy[k] = '';
          this._lastStatusUpdateDates.next([]);
          this._lastStatusUpdateStartDate.next('');
          this._lastStatusUpdateEndDate.next('');
          this._payPeriodDates.next([]);
          this._payPeriodStartDate.next('');
          this._payPeriodEndDate.next('');
        } else if (k.includes('totalRemittance')) {
          reqCopy[k] = null;
        } else {
          reqCopy[k] = [];
        }
      });
      this._closedRemittancesTableFilterRequest.next(reqCopy as ClosedRemittanceTableFilterRequest);
      this._activeFilterCount.next(reqCopy.getFilterCount());
    });
  }

  public resetFilterForm(): void {
    this.closedRemittancesTableFilterRequest$.once(req => {
      const reqCopy = Object.assign(new ClosedRemittanceTableFilterRequest(), req);
      reqCopy.clearFilters();
      this._closedRemittancesTableFilterRequest.next(reqCopy as ClosedRemittanceTableFilterRequest);
      this._activeFilterCount.next(0);
      this._lastStatusUpdateDates.next([]);
      this._lastStatusUpdateStartDate.next('');
      this._lastStatusUpdateEndDate.next('');
      this._payPeriodDates.next([]);
      this._payPeriodStartDate.next('');
      this._payPeriodEndDate.next('');
      this.clearDates$.next();
    });
  }

  public clearAllFilters(): void {
    this._closedRemittancesTableFilterRequest.next(new ClosedRemittanceTableFilterRequest());
    this._activeSearchCriteria.next(0);
    this._activeFilterCount.next(0);
    this._lastStatusUpdateDates.next([]);
    this._lastStatusUpdateStartDate.next('');
    this._lastStatusUpdateEndDate.next('');
    this._payPeriodDates.next([]);
    this._payPeriodStartDate.next('');
    this._payPeriodEndDate.next('');
    this.clearDates$.next();
  }

  private fetchClosedRemittances(
    filterRequest: ClosedRemittanceTableFilterRequest,
    orderbyRequest: ClosedRemittanceTableOrderbyRequest,
    currPage: number,
    pageSize: number
  ) {
    this._loadingRemittances.next(true);
    const oDataParams = new ODataQueryOptions();
    const filterString = filterRequest.getFilterString();
    const orderbyString = orderbyRequest.getOrderByString();
    if (!!filterString) {
      oDataParams.setFilter(filterString);
    }
    if (!!orderbyString) {
      oDataParams.setOrderBy(orderbyString);
    }
    oDataParams.setCount(true);
    oDataParams.setTop(pageSize);
    oDataParams.setSkip(currPage * pageSize);
    // TODO: Get the actual employerID from the route
    this.remittanceDomainModel.getClosedRemittancesForEmployer(1, oDataParams).subscribe({
      next: res => {
        this._remittances.next(res.value);
        this._remittanceTotalCount.next(res['@odata.count'] ?? 0);
      },
      complete: () => {
        this._loadingRemittances.next(false);
      },
      error: (error: CustomError) => {
        this._loadingRemittances.next(false);
        this.toastService.publishError(error);
      }
    });
  }
}
