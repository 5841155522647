import { Deserializable } from '../protocols/deserializable';

export class RemittanceVerificationMember implements Deserializable {
  public id!: string; // This ID matches the contribution ID
  public firstName!: string;
  public lastName!: string;
  public socialInsuranceNumber!: string;
  public birthDate!: string;
  public internalUserVerified!: boolean;
  public potentialMatches!: RemittancePotentialMatchedMember[];

  // Not From API
  public fullName!: string;

  onDeserialize(): void {
    const Deserialize = window?.injector?.Deserialize;
    this.fullName = `${this.firstName} ${this.lastName}`;
    this.potentialMatches = Deserialize?.arrayOf(RemittancePotentialMatchedMember, this?.potentialMatches);
  }
}

export class RemittancePotentialMatchedMember implements Deserializable {
  public memberId!: number;
  public firstName!: string;
  public lastName!: string;
  public socialInsuranceNumber!: string;
  public birthDate!: string;

  onDeserialize(optionalParam?: any): void {}
}
