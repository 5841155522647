<div class="auth-flow-title" i18n>Sign In</div>

<lib-reactive-form-group #form [bindTo]="req$ | async" (formSubmitted$)="formSubmitted($event)">
  <lib-reactive-form-email
    [inputName]="'email'"
    label="Email"
    i18n-label
    [bindingProperty]="'email'"
    [required]="true"
    [placeholder]="''">
  </lib-reactive-form-email>

  <lib-reactive-form-password
    [inputName]="'password'"
    [required]="true"
    label="Password"
    i18n-label
    [placeholder]="''"
    (keydown.enter)="form.submitForm()"
    [bindingProperty]="'password'">
  </lib-reactive-form-password>
</lib-reactive-form-group>

<div class="sign-in-button-container">
  <lib-button-primary [style.width.%]="100" (buttonClicked)="form.submitForm()">
    <ng-container i18n>Sign In</ng-container>
  </lib-button-primary>
</div>
<button class="paddingless-text-button mt-2" (click)="secondaryButtonPressed()">
  <ng-container i18n>Forgot Password ?</ng-container>
</button>
