import { Component, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import 'firebase/analytics';
import { CanDeactivateState } from './services/guards/can-deactivate.guard';
import { filter, tap } from 'rxjs/operators';
import { InjectorService } from './services/injector.service';
import { ScreenService } from './services/screen-service.service';
import { BaseComponent } from './models/base/base-component';
import { fromEvent } from 'rxjs';
import { SessionService } from './services/session-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {
  constructor(
    private router: Router,
    // @ts-ignore
    private injectorService: InjectorService, // Do not remove, or else injector service does not work.
    private screenService: ScreenService,
    private sessionService: SessionService,
    private el: ElementRef
  ) {
    super();

    // Config for Abandon changes on back button
    window.onpopstate = () => (CanDeactivateState.defendAgainstBrowserBackButton = true);
    router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        tap(() => (CanDeactivateState.defendAgainstBrowserBackButton = false))
      )
      .subscribe();
  }

  setupViews() {
    this.onResize(window?.innerWidth);
  }

  setupBindings() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    fromEvent(window, 'resize').subscribeWhileAlive({
      owner: this,
      next: event => {
        const target = event.target as Window;
        this.onResize(target.innerWidth);
      }
    });

    fromEvent<StorageEvent>(window, 'storage').subscribeWhileAlive({
      owner: this,
      next: (event: StorageEvent) => {
        if (event.key === 'SessionContainer' && event.newValue === null) {
          this.sessionService.destroySession.next(true);
          this.router.navigate(['/auth/sign-in']).then();
        }
      }
    });
  }

  private onResize(windowInnerWidth: number) {
    if (windowInnerWidth > 0) {
      this.screenService.setWidth(windowInnerWidth);
      this.screenService.setIsMobile(windowInnerWidth <= ScreenService.MOBILE_WIDTH);
    }
  }
}
